import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles/var.css'
import './assets/styles/main.scss'
import router from './router/index.js'
import { createPinia } from 'pinia'
const pinia = createPinia()
import 'animate.css';
import 'vue-fullpage.js/dist/style.css'
import VueFullPage from 'vue-fullpage.js'
import Vue3TouchEvents from "vue3-touch-events";
import { createMetaManager } from "vue-meta";

const app = createApp(App)

app.use(VueFullPage)
app.use(Vue3TouchEvents, {
  dragFrequency: 1000,
  swipeTolerance: 70
})
app.use(router)
app.use(createMetaManager())
// app.use(pinia)

app.use(pinia).mount('#app')
// app.mount('#app')
