<template>
<div id="app">
  <Header />
  <main>
    <router-view/>
  </main>
  <Footer v-if="$route.path !== '/'" />
  <BaseModal v-if="baseStore.isOpenModal"/>
  <metainfo></metainfo>
</div>
</template>

<script>
import { index } from "@/store/base";
import Header from './components/Header.vue'
import BaseModal from "@/components/ui/modals/BaseModal.vue";
export default {
  name: 'App',
  components: {
    // Footer,
    Header,
    BaseModal
  },
  setup() {
    const baseStore= index()

    return {
      baseStore
    }
  }
}
</script>

<style lang="scss">
</style>
