import { defineStore } from 'pinia'
const initialState = {
    screen: 1,
    maxScreen: 6,
    fourScreenStep: 0,
    fiveScreenStep: 0,
    slideIndex: 0,
    slideIndexThird: 0,
    changeHeader: false,
    isHomePage: false,
    isActiveHomePage: false
}
export const IndexStore = defineStore('index', {
    state: () => ({
        screen: 1,
        maxScreen: 6,
        fourScreenStep: 0,
        fiveScreenStep: 0,
        slideIndex: 0,
        slideIndexThird: 0,
        changeHeader: false,
        isHomePage: false,
        isActiveHomePage: false
    }),
    actions: {
        clearStore() {
            this.$state = initialState
        }
    },
})
