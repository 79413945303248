<script setup>
import Footer from "@/components/Footer";
import SuccessMessage from "@/components/ui/messages/successMessage.vue";
import isMobile from "@/helpers/isMobile";
import { ref, defineProps } from "vue"
import { sendContactFormReq } from "@/api";
import useToggleVisibility from "@/hooks/useToggleVisible";

const props = defineProps({
  fullPage: {
    required: true
  }
})

let formFields = ref([
  {
    name: 'Name',
    value: '',
    type: 'text',
    nameField: 'name',
  },
  {
    name: 'E-mail',
    value: '',
    type: 'email',
    nameField: 'email'
  },
  {
    name: 'Phone',
    value: '',
    type: 'number',
    nameField: 'phone'
  },
  {
    name: 'Company',
    value: '',
    type: 'text',
    nameField: 'company'
  }
])

let position = ref(false)

const {
  toggleVisibility,
  setToggleVisibility,
  closeNotification
} = useToggleVisibility()

const changeSectionOnScroll = ({ target: { scrollTop }}) => {
  if (isMobile(768)) {
    if (scrollTop === 0) {
      return props.fullPage.api.moveSectionUp()
    }
  }
}

const sendForm = async () => {
  const dataForSend = [...formFields.value]
  const resultData = dataForSend.reduce((acc, current) => (
    { ...acc, [current.nameField]: current.value }
  ),{})

  try {
    const res = await sendContactFormReq(resultData)
    if (res.data.success) {
      setToggleVisibility(true)
      closeNotification(true)
      formFields.value.forEach( field => {
        field.value = ''
      })
    }
  } catch (error) {
    console.log(error,'error ups')
  }
}

</script>

<template>
  <div
    class="screen screen-sixth"
    id="sixth-screen"
    :style="{position: position ? 'relative' : ''}"
    @scroll="changeSectionOnScroll"
  >
    <div class="container screen__block">
      <div class="screen__body">
        <h2 class="screen__title" id="test1"></h2>
        <h2 class="screen__title mob-screen__title" >Others promise, but we find what you need. And we are responsible for the result.</h2>
        <form id="form" class="form" @submit.prevent="sendForm">
          <h4 class="form__title">Sign up for a live presentation</h4>
          <p
            class="form__subtitle"
            v-if="!toggleVisibility"
          >
            We will tell you about the functions, train the staff, make a trial deal
          </p>
          <SuccessMessage
            v-else
          />
          <label
            class="form__group"
             v-for="(formField, i) in formFields"
             :key="i"
          >
            <p class="form__label">{{ formField.name }}</p>
            <input required :type="formField.type" v-model="formField.value" class="form__input">
          </label>
          <button
            class="form__btn">
            <span>Schedule a demo</span>
          </button>
        </form>
      </div>
    </div>
    <Footer
      v-if="isMobile(768)"
    />
  </div>
</template>
<style>
.fp-table {
  justify-content: unset !important;
}
</style>
<style lang="scss" scoped>

.screen-sixth {
  position: relative !important;
  .screen__block {
    @media screen and (max-width: 550px) {
      height: 100vh !important;
    }
  }
  .form__btn {
    min-height: 53px;
  }
  @media screen and (max-width: 768px) {
    height: 100% !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;

    .screen__body {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 50px;
      height: auto;
    }

    .form {
      bottom: auto;
      visibility: visible;
      height: auto;
      position: relative;
      margin: 0 auto;
    }
  }
  @media screen and (max-width: 500px) {
    .form {
      margin: initial;
      width: 100%;
    }
  }
  .container {
    height: auto;
    @media screen and (max-width: 768px) {
      padding-top: 10rem;
      width: auto;
    }
    @media screen and (max-width: 500px) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .screen__title {
    font-weight: 800;
    font-size: 6.2rem;
    text-align: center;
    @media screen and (max-width: 768px) {
      padding-top: 20rem;
      opacity: .4;
      display: none;
      width: 100%;
    }
    @media screen and (max-width: 500px) {
      font-size: 35px !important;
      line-height: 45px !important;
    }
    @media screen and (max-width: 500px) {
      font-size: 30px !important;
      line-height: 35px !important;
    }
  }
  .form {
    @media screen and (max-width: 375px) {
      max-width: 355px;
      width: 100%;
    }
  }
}

.screen {
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 100vh;
  background-color: $black;
  overflow: hidden;
  transition: 2s ease;
  &__block {
    height: 100%;
  }
  &__body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  &__title {
    //position: absolute;
    //top: 16rem;
    //display: flex;
    //flex-direction: column;
    //animation: top 3s forwards;
    //animation-delay: 7.5s;
    width: 91%;
    margin: 0 auto;
    align-items: center;
    color: $purple2;
    font-size: 7.1rem;
    line-height: 8.8rem;
    min-height: 9.5rem;
    font-family: 'Neue Machina', sans-serif;
    font-weight: 500;
    &.animate {
      animation-name: top;
      animation-fill-mode: forwards;
      animation-duration: 3s;
    }

  }
}
.form {
  position: absolute;
  width: 54rem;
  background: rgba(28, 28, 28, 0.95);
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4.2rem 0 3.2rem 0;
  right: 0;
  left: 0;
  bottom: -60%;
  backdrop-filter: blur(20rem);
  margin: auto;
  font-family: 'Helvetica', sans-serif;
  visibility: hidden;
  height: 0;
  transition: height 2s;
  &.animate-height {
    height: 16rem;
    visibility: visible;
    overflow: hidden;
    //animation: open 3s forwards;
  }
  &.animate-final {
    height: 44rem;
    //animation: open 2s forwards;
  }
  &__title {
    font-size: 2.1rem;
    color: $light;
    font-family: 'Neue Machina', sans-serif;
  }
  &__subtitle {
    font-size: 1.3rem;
    width: 29rem;
    text-align: center;
    margin: 1.8rem 0 4.4rem 0 ;
    color: $light;
  }
  &__group {
    position: relative;
    margin-top: 1.2rem;
    p {
      color: $light;
      opacity: 0.5;
      position: absolute;
      font-size: 1.1rem;
      top: .8rem;
      left: 1.6rem;
    }
  }
  &__input {
    width: 28.8rem;
    height: 5rem;
    background: #242424;
    box-sizing: border-box;
    border: .1rem solid #494949;
    padding: 1.3rem 1.6rem 0 1.6rem;
    color: $light;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }
  &__btn {

    padding: 1.5rem 7.8rem;
    background: $purple;
    border-radius: 3rem;
    margin-top: 2rem;
  }
}

.form__btn {
  overflow: hidden;
  position: relative;
  &:disabled {
    opacity: .6;
  }
  span {
    z-index: 20;
    font-weight: 400;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: $light;
  }

  &:after {
    background: #fff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all .4s ease-in !important;
    width: 50px;
    z-index: 12;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
  }
  &:hover {

    &:after {
      left: 120%;
      transition: all 2.5s ease-in;
    }
  }
}

.form__btn:hover {

  &:after {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
  }
}

@keyframes open {
  0% {
    opacity: 0;
    top: 100rem;
    visibility: visible;
    height: 0;
  }
  100% {
    opacity: .95;
    height: 43.2rem;
    top: 8rem;
    visibility: visible;
  }
}
@keyframes top {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20rem);
    opacity: .4;
  }
}

input[type='number'] {
  -moz-appearance:textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@media screen and (max-width: 450px) {
  .screen {
    &__title {
      text-align: center;
      font-size: 2.5rem;
      line-height: 2.8rem;
    }
  }
  .form {
    width: auto;
    max-width: 370px;
    &__title {
      font-size: 1.7rem;
    }
    &__subtitle {
      font-size: 1.5rem;
    }
  }
}
.screen-sixth  {
  .mob-screen__title {
    display: block;
    opacity: 1;
    padding-top: 5rem;
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 55px;
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}
</style>
