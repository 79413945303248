export const sixthScreenAnimations = (() => {
    function animateText(text, element) {
        const intervalId = setInterval(() => {
            element.textContent += text[index];
            index++;
            if (index === text.length) {
                clearInterval(intervalId);
                form.classList.add('animate-height')
                setTimeout(() => {
                    parent.classList.add('animate')
                    form.classList.add('animate-final')
                }, 2000)
                setTimeout(() => {
                    form.style.visibility = 'visible'
                }, 2100)
            }
        }, 60);
        let index = 0;
    }
    const form = document.getElementById('form')
    const parent = document.getElementById('test1')
    // const sixthSection = document.getElementById('sixth-section')
    // const sixthScreen= document.getElementById('sixth-screen')
    const text = "Others promise, but we find what you need. And we are responsible for the result.";

    if (!parent.textContent.length) {
        animateText(text, parent);
    }
})

export const clearText = (() => {
    const parent = document.getElementById('test1')
    parent.textContent = "Others promise, but we find what you need. And we are responsible for the result.";
})