<script setup>
  import { ref, onUnmounted, watch } from "vue";
  import { IndexStore } from "@/store";
  import { nav } from "@/assets/nav";
  import MobileMenu from "@/components/mobile-menu/MobileMenu.vue";
  import { useRoute } from "vue-router";

  const route = useRoute()
  const $store = IndexStore();

  const isOpen = ref(false)
  const timeout = ref(null)
  const rippleAnimation = ref(false)

  const openMobileMenu = () => {
    isOpen.value = true
    timeout.value = setTimeout(() => {
      rippleAnimation.value = true
    }, 0)
  }

  //Methods
  const clickOnLink = (item) => {
    if (route.name === 'Home') {
      item.link === '/' ? $store.isHomePage = true : null
      setTimeout(() => {
        $store.isHomePage = false
      }, 0)
    }
  }

  const closeMobileMenu = (val) => {
    isOpen.value = val
    rippleAnimation.value = val
  }

  watch(() => route.path, () => {
    isOpen.value = false
    rippleAnimation.value = false
  })

  onUnmounted(() => {
    clearTimeout(timeout)
  })

</script>
<template>
  <header
    id="header"
    :class="$route.path !== '/' ? 'absolute header-indent' : ''"
    :style="isOpen ? { zIndex: 999999, width: '50%' } : {zIndex: 10} "
  >
    <div class="header container">
      <div class="header__container">
        <img
          src="@/assets/images/logo-light.svg"
          alt="logo"
          class="header__logo"
          v-if="!$store.changeHeader"
          :style="isOpen ? {opacity: 0.1} : {opacity: 1}"
        >
        <img
          src="@/assets/images/logo-dark.svg"
          alt="logo"
          class="header__logo"
          v-else
          :style="isOpen ? {opacity: 0.1} : {opacity: 1}"
        >
        <ul class="header__navigation">
          <li class="header__list"
              v-for="(item, i) in nav"
              :key="i"
              :style="item.soon ? { pointerEvents: 'none' } : {}"
          >
            <router-link
              :to="item.link"
               class="header__link"
               :class="!$store.changeHeader ? 'light' : 'dark'"
               :style="{opacity: item.soon ? 0.25 : 1}"
               @click="clickOnLink(item)"
            >
              {{ item.name }}
              <span v-if="item.soon" :class="!$store.changeHeader ? 'light' : 'dark'">soon</span>
            </router-link>
          </li>
        </ul>
      </div>
      <button
        class="header-burger__btn"
        :class="{'hide': isOpen}"
        @click="openMobileMenu"
      >
        <svg
          :class="!$store.changeHeader ? 'gray' : 'purple'"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="11" width="48" height="2"/>
          <rect x="16" y="23" width="32" height="2" />
          <rect x="32" y="35" width="16" height="2"/>
        </svg>
      </button>
<!--      ?????-->
      <button
        class="header__login"
        style="display: none"
      >
        LOGIN
      </button>
<!--      ?????-->
    </div>
  </header>
  <mobile-menu
    :class="rippleAnimation ? 'ripple' : ''"
    v-if="isOpen"
    :isOpen="isOpen"
    :routeName="route.name"
    @close-menu="closeMobileMenu"
  />
</template>
<style lang="scss">
header {
  position: fixed;
  z-index: 10;
  top: 0;
  width: 100%;
  padding-top: 3rem;
  &.absolute {
    position: absolute;
  }
  @media screen and (max-width: 768px) {
    padding: 39px 10px 0 0;
  }
}
.header {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 550px) {
    padding-left: 17px !important;
    padding-right: 10px !important;
  }
  &__container {
    display: flex;
    align-items: center;
  }
  &__logo {
    width: 6.4rem;
  }
  &__navigation {
    display: flex;
    align-items: center;
    padding-left: 6.4rem;
    grid-column-gap: 4rem;
  }
  &__link {
    font-size: 1.8rem;
    font-family: Helvetica, sans-serif;
    span {
      font-family: Helvetica, sans-serif;
      font-weight: 300;
      font-size: 1.2rem;
      vertical-align: top;
    }
  }
  &__login {
    opacity: 0;
    background-color: $green;
    width: 9.7rem;
    height: 5.1rem;
    box-shadow: 0 0 6.4rem rgba(40, 199, 111, 0.7);
    border-radius: 3.2rem;
    color: $dark;
    font-size: 1.8rem;
    font-family: Helvetica, sans-serif;
  }
  &-burger__btn {
    background: transparent;
    position: absolute;
    right: 15px;
    svg {
      &.gray {
        fill: #ECE3EE;
      }
      &.purple {
        fill: #130A28;
      }
    }
    &.hide {
      display: none;
    }

    @media screen and (min-width: 769px) {
      display: none;
    }
    @media screen and (max-width: 500px) {
      right: 23px;
    }
  }
}
.light {
  color: $light;
}
.dark {
  color: $dark;
}
@media screen and (max-width: 768px) {
  .header {
    padding-top: 2rem;
    padding-bottom: 0;
    &__navigation {
      display: none;
    }
    &__logo {
      width: 6.4rem;
    }
  }
}
.header-indent {
  padding-top: 3rem;

  .container {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    padding: 39px 0 0 0;
  }
}
</style>
