<template>
  <div class="mobile-menu">
    <Transition name="fade">
      <div v-if="delayAnim">
        <div class="close-menu__wrap">
          <button
            class="close-menu__btn"
            @click="emit('closeMenu', false)"
          >
            <img src="@/assets/images/close-menu-icon.svg" alt="">
          </button>
        </div>
      </div>
    </Transition>
    <Transition name="slide-transform" appear>
      <div v-if="delayAnim">
        <div class="mobile-menu__content">
          <screen-image/>
          <ul class="header__navigation header-mobile__nav">
            <li class="mobile-nav__item"
                v-for="(navItem, idx) in nav"
                :key="idx"
            >
              <router-link
                :to="navItem.link"
                class="mobile-nav__link"
                :class="{'opacity': navItem.soon}"
                @click="clickOnLink(navItem)"
              >
                {{ navItem.name }}
                <sup v-if="navItem.soon">soon</sup>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import ScreenImage from "@/components/ScreenImage.vue";
import {nav} from "@/assets/nav";
import {ref, watch, onUnmounted, defineProps, defineEmits} from "vue";
import {IndexStore} from "@/store";
const $store = IndexStore();

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false
  },
  routeName: {
    type: String,
    default: ''
  }
})
const emit = defineEmits([
  'closeMenu'
])

const timeout = ref(null)
const delayAnim = ref(false)

watch(() => props.isOpen, () => {
  timeout.value = setTimeout(() => {
    delayAnim.value = true
  }, 700)
}, {immediate: true})

const clickOnLink = (item) => {
  if (props.routeName === 'Home') {
    const infoEl = document.getElementById('screen-1')
    item.link === '/' ? $store.isHomePage = true : null

    if ($store.isHomePage) {
      infoEl.scrollIntoView()
      setTimeout(() => {
        emit('closeMenu', false)
      }, 200)
    }

    setTimeout(() => {
      $store.isHomePage = false
    }, 0)
  }
}

onUnmounted(() => {
  clearTimeout(timeout.value)
})

</script>

<style lang="scss">
.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  background: #560071;
  clip-path: circle(31.1% at 100% 3%);
  transition: all 0.3s ease-in-out;
  opacity: 0;

  &.ripple {
    opacity: 1;
    clip-path: circle(89.1% at 65% 38%);
  }

  .close-menu__wrap {
    position: absolute;
    right: 10px;
    top: 35px;
    z-index: 10;

    .close-menu__btn {
      background: transparent;
    }
  }

  .mobile-menu__content {
    position: relative;
    padding-top: 250px;

    .screen__image {
      display: flex;
      position: absolute;
      top: 25%;
      left: 50%;
      right: 50% !important;
      transform: translate(-50%, -50%) scale(0.3) !important;
      background: transparent;
      scale: initial;
      .circle {
        background: #560071;
      }

      svg {
        path {
          fill: #ECE3EE;
        }
      }
    }

    .header-mobile__nav {
      display: flex;
      flex-direction: column;
      padding: 0;

      .mobile-nav__item {
        margin-bottom: 40px;

        &:last-child {
          margin-bottom: 0;
        }

        .mobile-nav__link {
          font-weight: 400;
          font-size: 24px;
          color: #ECE3EE;
          line-height: 33px;

          sup {
            color: #ECE3EE;
            font-size: 18px;
          }

          &.opacity {
            opacity: .2;
          }
        }
      }
    }
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s ease;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .slide-transform-enter-active {
    transition: all 0.3s ease-out;
  }
  .slide-transform-leave-active {
    transition: 0.3s ease-in-out;
  }
  .slide-transform-enter-from,
  .slide-transform-leave-to {
    transform: translateY(-150px);
    opacity: 0;
  }
}
</style>