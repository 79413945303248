export const mainImage = {
    1: {
        left: 1.7,
        rotate: -7
    },
    2: {
        left: 27.5,
        rotate: 12
    },
    3: {
        left: 44,
        rotate: -1
    },
    4: {
        left: 24,
        rotate: -9.5
    },
    5: {
        left: 35,
        rotate: 10
    },
    6: {
        left: 16.3,
        rotate: -4
    },
    7: {
        left: 28.3,
        rotate: 1
    },
    8: {
        left: 21,
        rotate: -14
    },
    9: {
        left: 35.8,
        rotate: 12
    }
}


export const messages = [
    {
        text: 'Create a company profile on the world trade web',
    },
    {
        text: 'Automatically generate contract, invoice and packing',
    },
    {
        text: 'Track status and reporting in real time',
    },
    {
        text: 'Post requests and offers in 2 clicks',
    },
    {
        text: 'Simply ask for lower prices or increase your margin',
    },
    {
        text: 'Chat with a personal manager accompanying your transaction',
    },
    {
        text: 'Receive notifications of necessary actions',
    },
    {
        text: 'Make transactions with foreign organizations without knowing the language through the platform interface',
    },
    {
        text: 'Get support right here, right now!',
    },
]


export const listScreen = [
    {
        name: 'arrow',
        1: {
            left: 20.8,
            top: 17.2,
            rotate: 0
        },
        2: {
            left: 26.7,
            top: 22.1,
            rotate: 0
        },
        3: {
            left: 63.3,
            top: 62.9,
            rotate: 0
        },
        4: {
            left: 102.9,
            top: 71.8,
            rotate: -23.43
        },
        5: {
            left: 123.6,
            top: 58,
            rotate: -70
        },
        6: {
            left: 133.7,
            top: 31.9,
            rotate: -100
        },
        7: {
            left: 133.4,
            top: 18.9,
            rotate: -140
        },
        8: {
            left: 132.8,
            top: 12.9,
            rotate: -190
        },
        9: {
            left: 150,
            top: -5,
            rotate: -190
        }
    },
    {
        name: 'circleGreen',
        1: {
            left: 67.8,
            top: 25.7
        },
        2: {
            left: 87,
            top: 14
        },
        3: {
            left: 99.4,
            top: 40.2
        },
        4: {
            left: 33.6,
            top: 23.6
        },
        5: {
            left: 3.8,
            top: 17.9
        },
        6: {
            left: 60.5,
            top: 24
        },
        7: {
            left: 59.5,
            top: 44
        },
        8: {
            left: 25.6,
            top: 47.7
        },
        9: {
            left: -30,
            top: -5
        }
    },
    {
        name: 'circlePurple',
        1: {
            left: 67.8,
            top: 37.6
        },
        2: {
            left: 53.4,
            top: 32.1
        },
        3: {
            left: 70.4,
            top: 49.6
        },
        4: {
            left: 18.2,
            top: 46.8
        },
        5: {
            left: 66.4,
            top: 19.8
        },
        6: {
            left: 80.6,
            top: 33.4
        },
        7: {
            left: 103.9,
            top: 52.4
        },
        8: {
            left: 71.7,
            top: 39
        },
        9: {
            left: 50,
            top: 120
        }
    },
    {
        name: 'circlePink',
        1: {
            left: 86.8,
            top: 39.1
        },
        2: {
            left: 87.6,
            top: 44.4
        },
        3: {
            left: 70,
            top: 30.2
        },
        4: {
            left: 70,
            top: 44.4
        },
        5: {
            left: 59.2,
            top: 71.8
        },
        6: {
            left: 22.7,
            top: 49
        },
        7: {
            left: 69.2,
            top: 24.9
        },
        8: {
            left: 46.4,
            top: 12.9
        },
        9: {
            left: 30,
            top: -20
        }
    },
    {
        name: 'circleWhite',
        1: {
            left: 115.7,
            top: 43.1
        },
        2: {
            left: 107.9,
            top: 50.6
        },
        3: {
            left: 92.8,
            top: 76.4
        },
        4: {
            left: 75.3,
            top: 71.8
        },
        5: {
            left: 117,
            top: 23.2
        },
        6: {
            left: 97.4,
            top: 33.9
        },
        7: {
            left: 84.2,
            top: 26.1
        },
        8: {
            left: 97.2,
            top: 33.9
        },
        9: {
            left: 160,
            top: 40
        }
    }
]
