<template>
  <div class="modal">
    <div class="modal-content">
      <slot name="header">
        <div class="modal-content__header">
          <button
            class="modal-close__btn"
            @click="closeModal"
          >
            <img src="@/assets/images/close-menu-icon.svg" alt="">
          </button>
        </div>
      </slot>
      <slot>
        <div>Some modal text</div>
      </slot>
    </div>
  </div>
</template>

<script setup>
import { index } from "@/store/base";

const baseStore = index()

const closeModal = () => {
  baseStore.$patch({
    isOpenModal: false
  })
}
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;

  &-content {
    background-color: white;
    min-width: 200px;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

    &__header {

      .modal-close__btn {
        background-color: transparent;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 5px;
        right: 5px;

        img {
          width: 100%;
          height: auto;
          filter: invert(1);
        }
      }
    }
  }
}
</style>