import { ref, onUnmounted } from "vue";

const useToggleVisibility = () => {
  const toggleVisibility = ref(false)
  const timer = ref(null)

  const setToggleVisibility = (bool) => {
    toggleVisibility.value = bool
  }

  const closeNotification = (bool) => {
    if (bool) {
      timer.value = setTimeout(() => {
        toggleVisibility.value = !bool
      }, 2000)
    }
  }

  onUnmounted(() => {
    clearTimeout(timer.value)
  })

  return {
    toggleVisibility,
    setToggleVisibility,
    closeNotification
  }
}

export default useToggleVisibility