<script setup>
  import DefaultSlider from "@/components/sliders/DefaultSlider.vue";
  import { items } from "@/assets/fiveScreen";
  import { ref, defineProps, watch } from "vue"
  import { setCustomMoveToSection } from "@/helpers/fullpageCustomOptions";
  import isMobile from "@/helpers/isMobile";

  const screen = ref(null);
  const refItemList = ref([])
  const transformVal = ref(-100)
  const titleTransformVal = ref(-200)
  const positionRight = ref('')
  const positionLeft = ref('')

  const props = defineProps({
    fullPage: {
      required: true
    },
    initAnimation: {
      type: Boolean,
      default: false
    }
  })

  watch(() => props.initAnimation, (newVal) => {
    if (!isMobile(1025)) {
      if (newVal) {
        screen.value.addEventListener('wheel', handleWheel)
      } else {
        screen.value.removeEventListener('wheel', handleWheel)
        transformVal.value = -100
        titleTransformVal.value = -200
      }
    }
  })

  const handleWheel = (e) => {
    const positionOnScreen = e.wheelDeltaY
    if (positionOnScreen > 0) {
      if (titleTransformVal.value === -200) {
        setCustomMoveToSection(props.fullPage, 'fourth')
      }
      if (transformVal.value === 0) {
        if (titleTransformVal.value === -150) {
          setCustomMoveToSection(props.fullPage, 'fourth')
        }
        titleTransformVal.value += -50
      } else {
        transformVal.value += 100
      }
    } else {
      if (titleTransformVal.value !== 0) {
        titleTransformVal.value -= -50
      } else {
        titleTransformVal.value = 0
        transformVal.value -= 100
      }
      const observerCallback = (entries) => {
        if (entries[0].isIntersecting) {
          setCustomMoveToSection(props.fullPage, 'sixth')
          screen.value.removeEventListener('wheel', handleWheel)
        }
      }

      const observer = new IntersectionObserver(observerCallback, {
        root: screen.value,
        threshold: 1.0,
      })

      observer.observe(refItemList.value[4])
    }
  }
</script>

<template>
<div
  class="screen screen-5 screen-five"
  ref="screen"
>
  <div class="container">
    <div class="screen-five__wrap">
      <div
        class="screen__info"
        :style="{
          transform: `translate(${transformVal}px, ${titleTransformVal}px)`,
        }"
      >
        <h2 class="screen__title">WTW NETWORK</h2>
        <p class="screen__subtitle">
          not just a tool, but a profitable trade
        </p>
      </div>
      <div
        class="item"
        v-for="(item, i) in items"
        :key="i"
        :class="'item-' + (i + 1)"
        :ref="el => refItemList.push(el)"
        :style="{
          transform: `translateX(${transformVal}px)`,
          opacity: !item.hide ? 1 : 0,
          right: positionRight,
          left: positionLeft
        }"
      >
        <p class="item__number">{{ i + 1 }}</p>
        <div class="item__right">
          <p class="item__title">{{ item.title }}</p>
          <p class="item__subtitle">{{ item.subtitle }}</p>
        </div>
      </div>
      <DefaultSlider
        class="def-slider"
        :customRef="props.fullPage"
      />
    </div>
  </div>
</div>
</template>
<style lang="scss">
.fp-slides {
  position: absolute !important;
  width: 100% !important;
  left: 0;
}
.fp-slidesContainer {
  //height: auto !important;
  width: 100% !important;
}
</style>
<style lang="scss" scoped>

.screen-five {
  height: 100vh !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;

  @media screen and (max-width: 550px) {
    height: 100% !important;
  }

  .item {
    @media screen and (max-width: 1024px) {
      position: fixed;
      pointer-events: initial;
      display: none;
    }
  }

  .container {
    height: 100%;
  }

  .screen-five__wrap {
    padding-top: 60px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 550px) {
      height: 100vh;
    }

    .screen__info {
      @media screen and (max-width: 1024px) {
        left: 10px;
        top: 90px;
        position: absolute;
        transform: initial !important;
        width: 100%;
      }
    }

    .item-1 {
      right: -50%;
      @media screen and (max-width: 1024px) {
        right: 0;
      }
    }
    .item-2 {
      right: -95%;
      @media screen and (max-width: 1024px) {
        right: -100%;
      }
    }
    .item-3 {
      right: -140%;
      @media screen and (max-width: 1024px) {
        right: -100%;
      }
    }
    .item-4 {
      right: -185%;
      @media screen and (max-width: 1024px) {
        right: -100%;
      }
    }
    .item-5 {
      right: -200%;
      @media screen and (max-width: 1024px) {
        right: -100%;
      }
    }
    .item-6 {
      right: -220%;
      @media screen and (max-width: 1024px) {
        right: -100%;
      }
    }
    .item-7 {
      right: -240%;
      @media screen and (max-width: 1024px) {
        right: -100%;
      }
    }
    .item-8 {
      right: -260%;
      @media screen and (max-width: 1024px) {
        right: -100%;
      }
    }
  }

  .screen__info {
    @media screen and (max-width: 550px) {
      top: 9rem;
    }
    @media screen and (max-width: 500px) {
      left: 10px;
      right: 10px;
    }
    position: fixed;
    top: auto;
    left: auto;
    transition: .3s;
    will-change: transform;
  }
  .screen__subtitle {
    animation: initial;
    width: auto;
    @media screen and (max-width: 768px) {
      transform: initial;
    }
  }
  .screen__title {
    @media screen and (max-width: 550px) {
      line-height: 60px;
    }
  }
  .items {
    @media screen and (max-width: 550px) {
      margin-top: 150px !important;
      padding-left: 0 !important;
      .item {
        padding-left: 5%;
      }
      .item__number {
        font-size: 13rem !important;
      }
      .item__title {
        max-width: 280px;
      }
      .item__subtitle {
        max-width: 300px;
      }
    }
  }
}

.slide {
  font-size: 10rem;
  color: white;
  z-index: 5;
  transform: translateY(10rem);
}
//.screen-5 {
//  overflow-y: auto !important;
//  overflow-x: hidden !important;
//}
.screen {
  position: relative;
  height: 100vh;
  background-color: $dark;
  overflow: hidden;
  &__block {
    padding-top: 22rem;
    position: relative;
  }
  &__title {
    font-size: 6.2rem;
    color: $purple2;
    letter-spacing: 0.2rem;
    font-family: 'Neue Machina', sans-serif;
  }
  &__subtitle {
    font-family: 'Neue Machina', sans-serif;
    font-weight: 700;
    padding-top: 1.2rem;
    font-size: 3.2rem;
    color: $light;
    transform: translateX(17rem);
  }
  &__info {
    transition: 1s;
    //position: absolute;
    top: 10rem;
    left: 0;
    text-align: left;
  }
  .items {
    display: flex;
    height: 12rem;
    margin-top: 100px;
    padding-left: 30%;
  }
}

@keyframes rotateImage {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes open {
  0% {
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 450px) {
  .screen {
    &__title {
      text-align: left;
      font-size: 5.5rem;
      line-height: 5.8rem;
      min-height: 5.5rem;
    }
    &__subtitle {
      transform: translateX(0);
    }
  }
}
.def-slider {
  display: none;
  @media screen and (max-width: 1024px) {
    display: block;
  }
}
</style>
