export const items = [
  {
    title: 'Reduce costs by 10-50%',
    subtitle: 'You will stop overpaying distributors, spending money on huge transfer fees, losing money and time due to unfair counterparties'
  },
  {
    title: 'Get access to a wide assortment',
    subtitle: 'Even products that are hard to find on the market can be found here. An assortment from 50 global producers is available here and now. You\'ll receive photos from the warehouse as proof of availability'
  },
  {
    title: 'Reduce the waiting time by 2-3 times',
    subtitle: 'You\'ll save time on finding a product and a vendor, on queuing at the manufacturer and on transportation, and as a result you\'ll receive your order in record time'
  },
  {
    title: 'WTW acts as a guarantor',
    subtitle: 'We protect both parties of the deal. You will be able to reduce trading risks to zero'
  },
  {
    title: 'WTW acts as a guarantor',
    subtitle: 'We protect both parties of the deal. You will be able to reduce trading risks to zero',
    hide: true
  },
]
