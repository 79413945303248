<script setup>
//IMPORTS
import FirstScreen from './../components/homePage/FirstScreen.vue'
import SecondScreen from './../components/homePage/SecondScreen.vue'
import ThreeScreen from './../components/homePage/ThreeScreen.vue'
import FourScreen from './../components/homePage/FourScreen.vue'
import FiveScreen from './../components/homePage/FiveScreen.vue'
import SixScreen from './../components/homePage/SixScreen.vue'
import ScreenImage from './../components/ScreenImage'
import { storeToRefs } from 'pinia'
import {ref, onMounted, watch, onUnmounted} from "vue";
import {IndexStore} from "@/store";
import Footer from "@/components/Footer";
import {useRouter} from 'vue-router'
import { useMeta } from "vue-meta";
import {setCustomSpeed} from "@/helpers/fullpageCustomOptions";
import { sixthScreenAnimations} from "@/assets/sixthScreenAnimations";
import isMobile from "@/helpers/isMobile";
//DATA
const router = useRouter();
const $store = IndexStore()
const { fourScreenStep, isHomePage } = storeToRefs($store)
const changeImageColor = ref(false)
let hideImage = ref(false)
let thirdScreenImage = ref(false)
let fullpage = ref(null)
const initFiveScreenAnimation = ref(false)
const containerMarginRight = ref('')

const isChangeSpeedAnimation = ref(true)

const fullPageOptions = {
  licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
  controlArrows: false,
  loopHorizontal: false,
  scrollingSpeed: 700,
  normalScrollElements: !isMobile(1025) ? '#five' : '',
  setAllowScrolling: false,
  touchSensitivity: 15,
  // autoScrolling: false,
  // navigation: true,
  anchors: ['first', 'second', 'third', 'fourth', 'fifth', 'sixth'],
  afterSlideLoad: function (section, origin, destination) {
    section.index === 4 ? $store.slideIndex = destination.index : false
    section.index === 2 ? $store.slideIndexThird = destination.index : false
  },
  beforeLeave: function (origin, destination, direction) {
    //MEDUSA
    if (destination.index === 1 && $store.slideIndexThird === 0) {
      changeImageColor.value = true
      $store.changeHeader = true
    } else {
      changeImageColor.value = false
      $store.changeHeader = false
    }
    if (origin.index === 1 && direction === 'down')  {
      thirdScreenImage.value = true
    }
    if (origin.index === 2 && $store.slideIndexThird === 0 && direction === 'up') {
      thirdScreenImage.value = false
    }
    if (origin.index === 2 && $store.slideIndexThird === 1 && direction === 'down') {
      hideImage.value = true
    }
    if (origin.index === 3 && direction === 'up') {
      hideImage.value = false
      thirdScreenImage.value = true
    }


  },
  onLeave: function (origin, destination, direction) {
    //3 screen
    if (origin.index === 2) {
      if (direction === 'down' && $store.slideIndexThird < 1) {
        fullpage.value.api.moveSlideRight();
        return false;
      } else if (direction === 'up' && $store.slideIndexThird > 0) {
        fullpage.value.api.moveSlideLeft();
        return false;
      }
    }
    //4 screen
    if (origin.index === 3) {
      fullpage.value.api.setAllowScrolling(true)
    }
    if (origin.index === 2 && direction === 'down') {
      fullpage.value.api.setAllowScrolling(false, 'down')
      $store.fourScreenStep = 0
    }
    if (origin.index === 4 && direction === 'up' && $store.slideIndex === 0) {
      $store.fourScreenStep = 8
      fullpage.value.api.setAllowScrolling(false, 'up')
    }

    // if (origin.index === 5) {
    //   if (!isMobile(768)) {
    //     clearText()
    //   }
    // }

    setCustomSpeed(fullpage, 700)
    if (origin.index === 1) {
      isChangeSpeedAnimation.value = direction !== 'down';
    }
  },
  afterLoad: function (origin, destination) {
    if (destination.anchor === 'fifth') {
      setTimeout(() => {
        initFiveScreenAnimation.value = true
      }, 400)
      if (isMobile(768)) {
        fullpage.value.api.setAllowScrolling(false, 'down')
        fullpage.value.api.setAllowScrolling(false, 'up')
      }
    } else {
      initFiveScreenAnimation.value = false
    }
    if (destination.anchor === 'sixth') {
      if (!isMobile(768)) {
        sixthScreenAnimations()
      }
    }
  }
}

// METHODS
const clickOnForm = async () => {
  hideImage.value = true
  $store.slideIndex = 3
  $store.slideIndexThird = 1
  const slides5 = document.querySelectorAll('.screen-5 .slide')
  const slides3 = document.querySelectorAll('.screen-3 .slide')
  slides3.forEach((el, i) => {
    el.classList.remove('active')
    i === slides3.length - 1 ? el.classList.add('active') : null
  })
  slides5.forEach((el, i) => {
    el.classList.remove('active')
    i === slides5.length - 1 ? el.classList.add('active') : null
  })
  fullpage.value.api.moveTo('sixth', 0)
}

//HOOKS
onMounted(() => {
  //delete Watermark
  document.querySelector('.fp-watermark').style.display = 'none';
  router.push('/')
  window.scrollTo(0, 0)
})

//WATCHER
watch(fourScreenStep, (newVal) => {

  if (newVal > 0 || newVal < 8) {
    fullpage.value.api.setAllowScrolling(false)
  }
  if (newVal === 8 ) {
    setTimeout(() => {
      fullpage.value.api.setAllowScrolling(true, 'down')
    },1000)
  }
  if (newVal === 0 ) {
    setTimeout(() => {
      fullpage.value.api.setAllowScrolling(true, 'up')
    },1000)

  }
})
watch(isHomePage, (newVal) => {
  if (newVal) {
    $store.slideIndex = 0
    $store.slideIndexThird = 0
    fullpage.value.api.moveTo('first', 0)
    const slides5 = document.querySelectorAll('.screen-5 .slide')
    const slides3 = document.querySelectorAll('.screen-3 .slide')
    slides3.forEach((el, i) => {
      el.classList.remove('active')
      i === 0 ? el.classList.add('active') : null
    })
    slides5.forEach((el, i) => {
      el.classList.remove('active')
      i === 0 ? el.classList.add('active') : null
    })
    setTimeout(() => {
      hideImage.value = false
      thirdScreenImage.value = false
    }, 500)
  }
})

const setStyle = (marginRight) => {
  containerMarginRight.value = marginRight
}

useMeta({
  title: 'World Trade Web',
  description: 'Your leading wholesale platform'
})

onUnmounted(() => {
  $store.clearStore()
})

</script>
<template>
<div class="home">
  <ScreenImage
    :changeColor="changeImageColor"
    :hidden="hideImage"
    :third="thirdScreenImage"
    :containerMarginRight="containerMarginRight"
  />
  <full-page
    ref="fullpage"
    :options="fullPageOptions"
    id="fullpage"
    :class="{'custom-speed__animation': isChangeSpeedAnimation}"
  >
    <div
      class="section"
      :class="$store.isActiveHomePage ? 'active' : ''"
    ><FirstScreen @containerMarginRight="setStyle" @register="clickOnForm"/></div>
    <div class="section"><SecondScreen /></div>
    <div class="section"><ThreeScreen @register="clickOnForm"/></div>
    <div class="section"><FourScreen/></div>
    <div
      class="section"
      id="five"
    >
      <FiveScreen
        :fullPage="fullpage"
        :initAnimation="initFiveScreenAnimation"
      />
    </div>
    <div class="section" id="sixth-section">
      <SixScreen
        :fullPage="fullpage"
      />
    </div>
    <div
      v-if="!isMobile(768)"
      class="section fp-auto-height"
      id="footer"
    >
      <Footer />
    </div>
  </full-page>
</div>
</template>
<style lang="scss" scoped>
.home {
  overflow: hidden;
}
.section {
  overflow: hidden;
}
#sixth-section {
  transition: 2s ease;
}
//#footer {
//  &.animate {
//    z-index: 2;
//    animation: top 2s forwards;
//  }
//}
//
//@keyframes top {
//  0% {
//  }
//  100% {
//    transform: translateY(-10rem);
//  }
//}

.wrap{
  margin-left: auto;
  margin-right: auto;
  width: 960px;
  position: relative;
}
h1{
  font-size: 6em;
}
p, h2{
  font-size: 2em;
}
h2{
  color:inherit;
}
.section{
  text-align:center;
}
#menu li {
  display:inline-block;
  margin: 10px;
  color: #000;
  background:#fff;
  background: rgba(255,255,255, 0.5);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
#menu li.active{
  background:#666;
  background: rgba(0,0,0, 0.5);
  color: #fff;
}
#menu li a{
  text-decoration:none;
  color: #000;
}
#menu li.active a:hover{
  color: #000;
}
#menu li:hover{
  background: rgba(255,255,255, 0.8);
}
#menu li a,
#menu li.active a{
  padding: 9px 18px;
  display:block;
}
#menu li.active a{
  color: #fff;
}
#menu{
  position:fixed;
  top:0;
  left:0;
  height: 40px;
  z-index: 70;
  width: 100%;
  padding: 0;
  margin:0;
}
#section0 img,
#section1 img{
  margin: 20px 0 0 0;
}
#section2 img,
#section3 img{
  margin: 20px 0 0 52px;
}
.slide {
  transition: 1s linear;
  &.active {
    transform: translateY(-10rem);
  }
}

.custom-speed__animation {
  transition: transform 1400ms ease 0s !important;
}
</style>
