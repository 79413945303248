<script setup>
import { ref, nextTick, defineEmits } from 'vue'

const emit = defineEmits([
  'containerMarginRight'
])

const container = ref(null)
const containerMargin = ref('')
let showImages = ref(false)
setTimeout(() => {
  showImages.value = true
}, 0)
const setLogoPosition = () => {
  if (container.value) {
    const style = getComputedStyle(container.value)
    containerMargin.value = style.marginRight
    emit('containerMarginRight', containerMargin.value)
  }
}

nextTick(() => {
  setLogoPosition()
  window.addEventListener('resize', setLogoPosition)
})

</script>
<template>
<div
  class="screen first-screen"
  id="screen-1">
  <img class="screen__background" src="@/assets/images/first-screen/background.png" alt="background">
  <div class="container screen__block" ref="container">
    <div class="screen__info" v-if="showImages">
      <h2 class="screen__title typingEffect">
        Your leading <br> wholesale <br> platform
      </h2>
      <p class="screen__subtitle">
        Trade with a network of verified counterparties around the world, find exclusive offers <br>
        and the best deal conditions
      </p>
      <div class="button-wrap">
        <span class="dot-button"></span>
        <a
          href="wtw.pdf"
          target="_blank"
          class="screen__button default-hover"
        >
          download presentation
        </a>
      </div>
    </div>
    <div class="screen__partners" v-if="showImages" >
      <img src="@/assets/images/first-screen/partners/hpe.svg" alt="image">
      <img src="@/assets/images/first-screen/partners/Huawei.svg" alt="image">
      <img src="@/assets/images/first-screen/partners/Aruba.svg" alt="image">
      <img src="@/assets/images/first-screen/partners/hitachi-vantara.svg" alt="image">
      <img src="@/assets/images/first-screen/partners/Dell.svg" alt="image">
      <img src="@/assets/images/first-screen/partners/Cisco.svg" alt="image">
      <img src="@/assets/images/first-screen/partners/NZS.svg" alt="image">
    </div>
    <p class="screen__footer" v-show="showImages">More than 50 direct suppliers</p>
  </div>
</div>
</template>
<style lang="scss" scoped>
.first-screen {
  padding: 0 10px;

  .screen__button {
    @media screen and (max-width: 550px) {
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1280px) {
    .screen__info {
      height: 52rem;
    }
    .screen__subtitle {
      padding-top: 1rem;
    }
    .button-wrap {
      margin-top: 1rem;
    }
    .screen__block {
      padding-top: 11.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    .screen__background {
      top: 50%;
      left: 50%;
      right: auto;
      transform: translate(-50%, -50%);
      height: 100%;
      width: auto;
    }
  }
  @media screen and (max-width: 550px) {
    height: auto !important;
    padding-bottom: 70px !important;
  }

  @media screen and (max-width: 500px) {
    padding: 0 17px;
  }

  @media screen and (max-width: 450px) {
    .screen__block {
      padding-top: 8rem;
    }
  }

  .screen__info {
    @media screen and (max-width: 500px) {
      margin-top: 25px;
    }
  }
  .button-wrap {
    animation: fadeInLeft;
    animation-duration: 3s;

    @media screen and (max-width: 550px) {
      margin-top: 0;
    }
  }
}

.typingEffect {
  width: 0;
  overflow: hidden; /* Ensure the text is not visible until the typewriter effect*/
  font-size: 16px;
  white-space: nowrap; /* Keeps the text on a single line */
  animation: typing 3s steps(30) forwards, blink;
}

/* The typing animation */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
.screen {
  position: relative;
  background-color: $screen1;
  height: 100vh;
  animation: open 1s;
  overflow: hidden;
  &__block {
    position: relative;
    padding-top: 14.5rem;
    height: 100%;
    animation: initial;
    @media screen and (max-width: 550px) {
      padding-top: 10.5rem;
    }
  }
  &__background {
    position: absolute;
    right: 0;
    width: 62%;
    top: 0;
  }
  &__info {
    display: flex;
    flex-direction: column;
    height: 53rem;
    justify-content: space-between;
    @media screen and (max-width: 550px) {
      height: auto !important;
    }
  }
  &__title {
    font-size: 10.1rem;
    line-height: 8.8rem;
    min-height: 9.5rem;
    font-family: 'Neue Machina', sans-serif;
    text-align: left;
    color: $light;

    @media screen and (max-width: 768px) {
      font-size: 8rem;
    }
    @media screen and (max-width: 550px) {
      font-size: 4.6rem !important;
      margin-bottom: 32px;
      line-height: 58px;
    }
    @media screen and (max-width: 370px) {
      font-size: 4rem !important;
      line-height: 4.8rem !important;
    }
  }
  &__subtitle {
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    color: $light;
    font-size: 2.6rem;
    width: 56rem;
    line-height: 3.9rem;
    text-align: left;
    animation: fadeInLeft;
    animation-duration: 3s;

    @media screen and (max-width: 550px) {
      width: 50rem;
      margin-bottom: 32px;
      padding-top: 0 !important;
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 33.1rem;
    height: 5.6rem;
    background-color: $purple;
    color: $light;
    font-size: 1.6rem;
    border-radius: 4rem;
    text-transform: uppercase;

    @media screen and (max-width: 550px) {
      max-width: 95%;
      width: 100%;
    }
    @media screen and (max-width: 500px) {
      max-width: 100%;
    }
  }
  &__image {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 23.5rem;
    right: 0;
    width: 49.6rem;
    height: 49.6rem;
    border-radius: 50%;
    &-circle {
      position: absolute;
      width: 50.6rem;
      height: 50.6rem;
      animation: showCircle 5s;
    }
    &-logo {
      position: absolute;
      width: 34.1rem;
      height: 37.7rem;
      animation: showImages 3s;
    }
    &-text {
      width: 47rem;
      height: 48rem;
      -webkit-animation: 1s linear 0s normal none infinite running rotateImage;
      animation: 15s linear 0s normal none infinite running rotateImage, showImages 2s;
      
    }
  }
  &__partners {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 9.5rem;
    animation: fadeInUp;
    animation-duration: 3s;
    img {
      &:nth-child(1) {
        width: 12rem;
      }
      &:nth-child(2) {
        width: 13.4rem;
      }
      &:nth-child(3) {
        width: 11.1rem;
      }
      &:nth-child(4) {
        width: 13.9rem;
      }
      &:nth-child(5) {
        width: 18.5rem;
      }
      &:nth-child(6) {
        width: 10.3rem;
      }
      &:nth-child(7) {
        width: 14.8rem;
      }
    }
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      padding-right: 10px;
      grid-gap: 15px;
      justify-content: center;
    }
  }
  &__footer {
    color: $light;
    font-size: 1.6rem;
    opacity: .5;
    text-align: center;
    padding-top: 2.2rem;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
  }
}

@media screen and (max-width: 450px) {
  .screen {
    &__info {
      height: 41rem;
    }
    &__title {
      text-align: left;
      font-size: 5rem;
      line-height: 5.8rem;
      min-height: 5.5rem;
    }
    &__subtitle {
      line-height: 3.6rem;
      width: auto;
    }
    &__background {
      width: 100%;
    }
    &__partners {
      grid-row-gap: 2.5rem;
      justify-content: center;
      grid-column-gap: 5rem;
      padding-top: 3.5rem;
    }
    &__footer {
      padding-top: 3.2rem;
    }
  }
}

@keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes showCircle {
  0% {
    width: 1rem;
  }
  100% {
    width: 50.6rem;
  }
}
@keyframes showImages {
  0% {
    width: 1%;
  }
  100% {
    width: 100%;
  }
}
@keyframes open {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
</style>
