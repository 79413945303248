export const footer = [
  {
    name: 'Home',
    img: require('@/assets/images/footer/footer-logo.svg')
  },
  {
    name: 'Company',
    links: [
      {
        name: 'Team',
        link: '/',
        soon: true
      },
      {
        name: 'Partners',
        link: '/',
        soon: true
      },
      {
        name: 'Vacancys',
        link: '/',
        soon: true
      },
      {
        name: 'News',
        link: '/',
        soon: true
      },
      {
        name: 'Reviews',
        link: '/',
        soon: true
      },
      {
        name: 'Contact',
        link: '/contact',
        soon: true
      }
    ]
  },
  {
    name: 'Resources',
    links: [
      {
        name: 'Request demo',
        link: '/',
        soon: true
      },
      {
        name: 'Knowledge',
        link: '/',
        soon: true
      },
      {
        name: 'F.A.Q.',
        link: '/',
        soon: true
      },
    ]
  },
  {
    name: 'Social & e-mail',
    links: [
      {
        name: 'LinkedIn',
        link: '/',
        icon: require('@/assets/images/footer/linkedin.svg')
      },
      {
        name: 'Telegram',
        link: '/',
        icon: require('@/assets/images/footer/telegram.svg')
      },
      {
        name: 'Facebook',
        link: '/',
        icon: require('@/assets/images/footer/facebook.svg')
      },
      {
        name: 'info@wtw.network',
        email: true
      },
      {
        name: 'info@wtwnetwork.com',
        email: true
      },
    ]
  }
]
