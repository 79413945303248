<script setup>
// import ScreenImage from "@/components/ScreenImage.vue";
// import isMobile from "@/helpers/isMobile";
import { ref, computed } from 'vue'
import { IndexStore } from "@/store";
const $store = IndexStore()

let startAnimationText = ref(false);
const sales = 'One sales manager will be able to control the process of placing offers, closing deals and tracking shipments of your goods around the world'
const purchasing = ' One purchasing manager will be able to control the process of placing requests, processing transactions and tracking the delivery of your equipment from anywhere in the world'

const screenWidth = window.innerWidth
const setTransformValue = computed(() => {
  if (screenWidth > 370 && screenWidth <= 968) {
    return 22
  } else if (screenWidth <= 370) {
    return 19
  } else {
    return 32
  }
})

</script>

<template>
<div class="screen screen-3 screen-three">
  
  <div class="slide"></div>
  <div class="slide"></div>

  <div class="screen__background">
    <img src="@/assets/images/three-screen/circle-mini.svg" alt="image" class="screen__background-image">
    <img src="@/assets/images/three-screen/circle-big.svg" alt="image" class="screen__background-image">
  </div>
  <div class="container screen__block screen-three__container">
    <h2 class="screen__title">
      World trade web
      will replace
      the department:
    </h2>
    <div class="screen__parent">
      <div class="screen__info">
        <div class="screen__subtitle__body">
          <p class="screen__subtitle" :class="startAnimationText ? 'changeText' : ''">
            {{ $store.slideIndexThird === 1 ? sales : purchasing }}
          </p>
        </div>
        <div class="button-wrap">
          <span class="dot-button"></span>
          <button
            class="screen__button default-hover"
            @click="$emit('register')"
          >
            Sign up for a live presentation
          </button>
        </div>
<!--        <screen-image-->
<!--          v-if="isMobile(768)"-->
<!--          style="position: static; display: flex"-->
<!--        />-->
      </div>
      <div class="screen__content">
        <div class="screen__buttons">
          <button
              class="screen__buttons-purchasing btn-desc"
              :class="$store.slideIndexThird === 0 ? 'screen__buttons-active' : ''"
              :style="`transform: translate(
              ${$store.slideIndexThird === 0 ?
              setTransformValue : 0
              }rem, 0rem);`"
          >
            purchasing
          </button>
          <button
            class="screen__buttons-purchasing btn-mob screen__buttons-active"
          >
            purchasing
          </button>
          <img class="screen__buttons-arrow" src="@/assets/images/three-screen/arrow.svg" alt="arrow">
          <button
              class="screen__buttons-sales btn-desc"
              :class="$store.slideIndexThird === 1 ? 'screen__buttons-active' : ''"
              :style="`transform: translate(-${$store.slideIndexThird === 1 ?
              0 : setTransformValue
              }rem, 0rem);`"
          >
            sales
          </button>
          <button
            class="screen__buttons-sales btn-mob"
          >
            sales
          </button>
        </div>
        <img src="@/assets/images/three-screen/image.webp" alt="image" class="screen__content-image">
      </div>
    </div>

  </div>
</div>
</template>
<script>

</script>
<style lang="scss" scoped>
.screen__subtitle__body {
  min-height: 20rem;
}
.screen-three {
  .button-wrap {
    margin-top: 4.9rem;
    width: 33.1rem !important;
    height: 5.6rem;
  }
  .screen__content-image {
    @media screen and (max-width: 1050px) {
      width: 100%;
    }
  }
  .screen-three__container {
    @media screen and (max-width: 968px) {
      width: auto;
    }
    @media screen and (max-width: 500px) {
      padding: 0 10px 100px 10px !important;
    }
  }
  @media screen and (max-width: 968px) {
    height: 100% !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
  }
  @media screen and (max-width: 500px) {
    padding-top: 80px;
    .button-wrap {
      margin-top: 1.9rem !important;
      max-width: 100%;
      width: 100% !important;
    }
    .screen__button {
      max-width: 100%;
      width: 100%;
    }
  }
  .btn-desc {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .btn-mob {
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 769px) {
      display: none;
    }
  }
}
.screen {
  position: relative;
  background-color: $indigo;
  height: 100vh;
  background-image: url("/src/assets/images/first-screen/background.png");
  background-size: cover;
  background-position: right;
  animation: open 1s;
  overflow: hidden;
  &__block {
    padding-top: 12.5rem;
    position: relative;
  }
  &__parent {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__info {
    z-index: 2;
    text-align: left;
  }
  &__background {
    position: absolute;
    left: 0;
    top: 15.8rem;
    &-image {
      &:nth-child(1) {
        position: absolute;
        top: 13rem;
        width: 36.6rem;
        height: 42.6rem;
        animation: showCircle 2s;
        
      }
      &:nth-child(2) {
        position: absolute;
        top: 0;
        width: 48.4rem;
        height: 68.4rem;
        animation: showCircleBig 2s;
      }
    }
  }
  &__title {
    font-size: 6.1rem;
    text-align: left;
    color: $light;
    width: 55.4rem;
    line-height: 6rem;
    letter-spacing: 0.1rem;
    font-family: 'Neue Machina', sans-serif;
  }
  &__subtitle {
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    margin-top: 4.8rem;
    color: $light;
    font-size: 2.4rem;
    width: 48rem;
    line-height: 3.3rem;
    padding: 1.2rem 3.6rem;
    background: rgba(92, 92, 92, 0.3);
    border-radius: 2rem;
    backdrop-filter: blur(2rem);
    animation: changeTextAnimation 1s;
    position: relative;
    z-index: 2;
  }
  &__button {
    width: 33.1rem;
    height: 5.6rem;
    background-color: $purple;
    color: $light;
    font-size: 1.6rem;
    border-radius: 4rem;
    text-transform: uppercase;
    position: relative;
  }
  &__buttons {
    display: flex;
    align-items: center;
    grid-column-gap: 3rem;
    position: relative;
    margin-top: 1rem;
    &-purchasing {
      width: 19.5rem;
      height: 6.9rem;
      background: rgba(92, 92, 92, 0.3);
      border-radius: 2rem;
      color: $light;
      font-size: 2.4rem;
      transition: 1s;
      font-family: 'Neue Machina', sans-serif;
    }
    &-sales {
      width: 19.5rem;
      height: 6.9rem;
      background: rgba(92, 92, 92, 0.3);
      border-radius: 2rem;
      color: $light;
      font-size: 2.4rem;
      font-family: 'Neue Machina', sans-serif;
      transition: 1s;
    }
    &-active {
      background: rgba(115, 103, 240, 0.3);
    }
  }
  &__image {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 45.5rem;
    right: -21rem;
    width: 49.6rem;
    height: 49.6rem;
    border-radius: 50%;
    &-circle {
      position: absolute;
      width: 17.1rem;
      height: 17.1rem;
    }
    &-logo {
      position: absolute;
      width: 15.8rem;
      height: 17.5rem;
    }
    &-text {
      width: 21.3rem;
      height: 21.4rem;
      -webkit-animation: 1s linear 0s normal none infinite running rotateImage;
      animation: 15s linear 0s normal none infinite running rotateImage, showImages 2s;
      
    }
  }
  &__content {
    top: -8rem;
    position: relative;
    &-image {
      width: 62.4rem;
      transform: translate(-5rem, 6rem);
      border-radius: 1.2rem;
    }
  }
}
.changeText {
  animation: changeTextAnimation 1s;
}

@keyframes changeTextAnimation {
  0% {
    transform: translate(0rem, 5rem);
    opacity: 0;
  }
  100% {
    transform: translate(0rem, 0rem);
    opacity: 1;
  }
}

@keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateImage {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes showCircle {
  0% {
    position: absolute;
    width: 1rem;
  }
  100% {
    position: absolute;
    width: 36.6rem;
  }
}
@keyframes showCircleBig {
  0% {
    position: absolute;
    width: 1rem;
  }
  100% {
    position: absolute;
    width: 48.4rem;
  }
}
@keyframes open {
  0% {
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

@media screen and (max-width: 968px) {
  .screen {
    &__title {
      text-align: left;
      font-size: 5.2rem;
      line-height: 5.4rem;
      min-height: 5.5rem;
      width: 100%;
      margin-bottom: 16px;
    }
    &__parent {
      flex-direction: column-reverse;
    }
    &__subtitle {
      width: auto;
      margin-top: 1.8rem;
    }
    .screen__info {
      margin-top: 20px;
    }
    &__block {
      flex-direction: column;
      padding-top: 9rem;
    }
    &__content {
      top: initial;
      padding-top: 0;
      max-width: 700px;
      margin: 0 auto;
      &-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transform: translate(0rem, 3rem);
      }
    }
    &__buttons {
      grid-column-gap: 1rem;
      &-purchasing {
        width: 12.5rem;
        font-size: 2rem;
      }
      &-sales {
        width: 12.5rem;
        font-size: 2rem;
      }
    }
    .button-wrap {
      margin-top: 3.9rem;
    }
    .screen__subtitle__body {
      min-height: initial;
    }
  }
}

@media screen and (max-width: 450px) {
  .screen-three__container {
    .screen__buttons {
      grid-column-gap: 2rem;
    }
  }
}

@media screen and (max-width: 370px) {
  .screen-three__container {
    .screen__title {
      font-size: 4.2rem;
      line-height: 4.5rem;
    }
    .screen__buttons-arrow {
      max-width: 48px;
      height: auto;
      object-fit: contain;
    }
    .screen__buttons {
      grid-column-gap: 1rem;
      .screen__buttons-sales {
        font-size: 1.5rem;
        width: 11.5rem;
      }
      .screen__buttons-purchasing {
        font-size: 1.5rem;
        width: 11.5rem;
      }
    }
  }
}
</style>
