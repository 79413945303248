<script setup>
import { ref } from 'vue'
import {
  listScreen,
  mainImage,
  messages
} from '@/assets/fourScreenAnimation'
import {IndexStore} from "@/store";
import isMobile from "@/helpers/isMobile"
const $store = IndexStore();

function parentStylePos(imgId) {
  let pos = 0
  for(let img of document.querySelectorAll(".main__image")) {
    if (!img) return

    if(img.id === imgId) {
      pos = img.offsetTop
    }
  }

  return `transform: translate(0, -${pos}px)`
}

function imgStylePos(i) {
  return `transform: rotate(${mainImage[i].rotate ? mainImage[i].rotate : 0}deg) translateX(${mainImage[i].left}px)`
}

let flag = ref(true)
function elementStyle (i) {
  return `top: ${listScreen[i][$store.fourScreenStep + 1].top}rem;
    left: ${listScreen[i][$store.fourScreenStep + 1].left}rem;
    transform: rotate(${listScreen[i][$store.fourScreenStep + 1].rotate ? listScreen[i][$store.fourScreenStep + 1].rotate : 0}deg)`
}

const wheelFunc = (e) => {
  if (e.deltaY > 0) {
    $store.fourScreenStep < 8 ? $store.fourScreenStep++ : null
    flag.value = false
  } else {
    $store.fourScreenStep > 0 ? $store.fourScreenStep-- : null
    flag.value = false
  }
  setTimeout(function() {
    flag.value = true
  }, 500)
}


function changeManageSlides(flag, event) {
  const wheel = flag ? wheelFunc(event) : null;
  return !isMobile(868) ? wheel : false;
}
function changeManageSlidesTouchUp() {
  if ($store.fourScreenStep >= 8) return
  if (!isMobile(868))  return
  $store.fourScreenStep++
}

function changeManageSlidesTouchDown() {
  if ($store.fourScreenStep <= 0)  return
  if (!isMobile(868))  return
  $store.fourScreenStep--
}

function screenMessagesSetClass(key, state) {
  let isVisible = (state === key || state - 1 === key)
  if (isMobile(868)) {
    isVisible = (state === key)
  }
  return isVisible
}

// function test(e) {
//   if (e.changedTouches[0].clientY < 460) {
//     changeManageSlidesTouchDown()
//     if ($store.fourScreenStep === 8) {
//       console.log('8')
//     }
//   } else{
//     changeManageSlidesTouchUp()
//   }
//   console.log(e.changedTouches[0].clientY
//     ,'drug event')
// }

</script>

<!--v-touch:swipe.top="changeManageSlidesTouchUp"-->
<!--v-touch:swipe.down="changeManageSlidesTouchDown"-->
<!--v-touch:drag="test"-->
<template>
<div
    class="screen screen-four"
    @wheel="changeManageSlides(flag, $event)"
    ref="screen"
    v-touch:swipe.top="changeManageSlidesTouchUp"
    v-touch:swipe.down="changeManageSlidesTouchDown"
>
  
  <div class="container screen__block">
    <h2 class="screen__title">User-friendly interface <template v-if="!isMobile(868)"><br></template> for wholesale trade</h2>

    <div
        id="img_body"
        class="main__image_body"
        :style="`${parentStylePos(`img-${$store.fourScreenStep + 1 }`, $store.fourScreenStep + 1)}`"
    >
      <template v-for="img in Object.keys(mainImage).length" :key="img">
        <img
            :id="`img-${img}`"
            :class="$store.fourScreenStep === img - 1 ? '' : 'main__image-hide'"
            :src="require(`@/assets/images/four-screen/${img}.png`)"
            :style="`${imgStylePos($store.fourScreenStep + 1)}`"
            alt=""
            class="main__image"
        />
      </template>

    </div>
    <div class="screen__messages" :style="`transform: translate(0, -${$store.fourScreenStep * 12}rem)`">
      <p
        v-for="(message, key) of messages"
        :class="screenMessagesSetClass(key, $store.fourScreenStep) ? '' : 'screen__message-hide'"
        class="screen__message"
        :key="key"
      >
        {{ message.text }}
      </p>
    </div>
  </div>
  <div class="screen__elements container">
    <img
      v-for="(item, i) in listScreen"
      :key="i"
      :src="require(`@/assets/images/four-screen/element-${i}.svg`)"
      :style="elementStyle(i)"
      :alt="`element-${i}`"
    >
  </div>
</div>
</template>


<style lang="scss" scoped>
.screen-four {
  .screen__title {
    font-weight: 700;

    @media screen and (max-width: 868px) {
      line-height: 4.5rem;
    }
  }
  .container {
    @media screen and (max-width: 550px) {
      width: auto;
      padding-top: 10rem;
    }
  }
  .screen__elements {
    @media screen and (max-width: 868px) {
      display: none;
    }
  }
  .main__image_body {
    @media screen and (max-width: 868px) {
      width: 100%;
      margin-top: 0;
    }
    @media screen and (max-width: 550px) {
      grid-row-gap: initial;
    }
  }
  .screen__messages {
    @media screen and (max-width: 868px) {
      left: 30%;
      top: 17%;
      .screen__message {
        max-width: 80%;
        width: 100%;
      }
    }
    @media screen and (max-width: 550px) {
      left: 0;
      width: 100%;

    }
  }
  @media screen and (max-width: 550px) {
    height: 100% !important;
  }
}
.main__image {
  transition: 1s;
  &_body {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 45rem;
    z-index: 4;
    width: 63rem;
    border-radius: 2rem;
    transition: 1.5s;
    position: relative;
  }
  &-hide {
    transition: 1.5s;
    opacity: 0;
    animation: fade 1.5s;
  }
  width: auto;
  border-radius: 1.5rem;
  height: 45rem;

  @media screen and (max-width: 868px) {
    height: auto;
    width: 68%;
  }
}
.screen {
  overflow: hidden;
  position: relative;
  height: 100vh;
  background-color: $black;
  animation: open 1s;
  &__block {
    padding-top: 11rem;
    position: relative;
  }
  &__title {
    position: relative;
    font-weight: 500;
    z-index: 2;
    font-size: 6.1rem;
    text-align: center;
    color: $purple2;
    line-height: 6rem;
    letter-spacing: 0.1rem;
    font-family: 'Neue Machina', sans-serif;
  }
  &__elements {
    img {
      position: absolute;
      transition: 1s;
    }
  }
  &__block {
    position: relative;
  }
  &__messages {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 2rem;
    position: absolute;
    z-index: 5;
    left: 57.6rem;
    top: 46.2rem;
    transition: 1.5s;
  }
  &__message {
    transition: 3s;
    backdrop-filter: blur(25px);
    color: $light;
    font-size: 2.1rem;
    font-family: Helvetica-Regular, sans-serif;
    padding: 2.4rem 3.2rem;
    background: rgba(92, 92, 92, 0.3);
    border-radius: 2rem;
    width: 29.9rem;
    font-weight: 300;
    animation: showMessage .5s;
    &-hide {
      transition: .3s;
      opacity: 0;
      animation: fade .3s;
    }
  }
}
@keyframes open {
  0% {
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  100% {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fade {
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes showMessage {
  from {
    transform: translate(0, 10rem);
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }
}
@media screen and (max-width: 450px) {
  .screen {
    &__title {
      text-align: center;
      font-size: 4.5rem;
      line-height: 5.8rem;
      min-height: 5.5rem;
    }
    &__messages {
      left: 0;
      top: 250%;
    }
  }
  .main__image {
    &_body {
      width: auto;
    }
  }
}
</style>
