<template>
  <swiper
    class="default-slider"
    :slides-per-view="1"
    :speed="600"
    :space-between="20"
    :pagination="true"
    :modules="modules"
    @sliderMove="sliderMove"
  >
    <swiper-slide
      v-for="(item, idx) in items.slice(0, 4)"
      :key="idx"
    >
      <div
        class="item"
      >
        <p class="item__number">{{ idx + 1 }}</p>
        <div class="item__right">
          <p class="item__title">{{ item.title }}</p>
          <p class="item__subtitle">{{ item.subtitle }}</p>
        </div>
      </div>
    </swiper-slide>
  </swiper>
</template>

<script>
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss'
import { items } from "@/assets/fiveScreen";
import { setCustomMoveToSection } from "@/helpers/fullpageCustomOptions";

export default {
  name: "DefaultSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  props: {
    customRef: {
      required: true
    }
  },
  setup(props) {
    const sliderMove = (swiper) => {
      if (swiper.realIndex === 0 && swiper.touches.diff > 0) {
        setCustomMoveToSection(props.customRef, 'fourth')
      }
      if (swiper.realIndex === 3 && swiper.swipeDirection === 'next') {
        setCustomMoveToSection(props.customRef, 'sixth')
      }
    }

    return {
      items,
      sliderMove,
      modules: [Pagination]
    }
  }
}
</script>

<style lang="scss">
.default-slider {
  width: 100%;
  height: 100%;
  padding-top: 200px;
  max-height: 250px;

  @media screen and (max-width: 500px) {
    padding: 200px 10px 0 10px;
  }

  .item {
    width: 85%;
    display: flex !important;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .swiper-pagination {
    .swiper-pagination-bullet {
      opacity: 1;
      background-color: white;
      &-active {
        background-color: #6200FF;
      }
    }
  }
}
</style>