export const nav = [
  {
    name: 'Home',
    link: '/'
  },
  {
    name: 'About',
    link: '/',
    soon: true
  },
  {
    name: 'Contact',
    link: '/contacts',
    soon: false
  },
  {
    name: 'Reviews',
    link: '/',
    soon: true
  },
  {
    name: 'News',
    link: '/',
    soon: true
  }
]
